<template>
<div>
    <div>
      <div
        class="sidemenu-mask-dropdown"
        @click="(e) => {
            isOpen = !isOpen
        }"
        @mouseover="(e) => ellipsis(e, true)"
        @mouseleave="(e) => ellipsis(e, false)"
        :id="'reports-' + year + item.id"
        :type="item.id"
    >
        <div class="tooltip-hover" :open="false">
          <i :class="['status-icon fa fa-chevron-right', isOpen ? 'open' : 'closed']"></i>
          <i v-if="!isOpen" class="fa fa-folder"></i>
          <i v-if="isOpen" class="fa fa-folder-open"></i>
          {{ year }}
        </div>
    </div>
    <div class="sidemenu-mask-dropdown-list" :data-controlled-by="'reports-' + year + item.id" :open="isOpen" :level="getNextLevel() + 1" >
        <div v-for="report in sortByNameDesc(reports)" :key="report.Id">
            <div class="sidemenu-mask-item" @click.stop="getReport(report)" @mouseover="(e) => ellipsis(e, true)" @mouseleave="(e) => ellipsis(e, false)" :report="report.id" :style="padding">
                <div class="tooltip-hover" :open="false" v-tooltip="{
                    content: report.displayName,
                }">
                    <i class="fas fa-file"></i>
                    {{ report.displayName }}
                </div>
            </div>
        </div>
    </div>

  </div>
</div>
</template>

<script>
import _ from 'underscore'
import Constants from '../constants'

export default {
  name: 'MenuItemYear',
  props: {
    item: Object,
    year: String,
    reports: Array,
    level: Number,
    open: Boolean
  },
  watch: {
    open (isOpen) {
      if (!isOpen) {
        this.isOpen = false
      }
    }
  },
  data () {
    return {
      isOpen: false,
      sidemenu: document.querySelector('.sidemenu-mask')
    }
  },
  computed: {
    isIE () {
      var ua = navigator.userAgent
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    },
    padding () {
      if (!this.isIE) {
        return { paddingLeft: `calc(${this.getNextLevel() * 1.4}rem + 20px)` }
      }

      return {}
    }
  },
  methods: {
    sortByNameDesc (reports) {
      let nameParsedReports = []

      reports.map(function (report) {
        report.name = report.displayName
        nameParsedReports.push(report)
      })

      let duplicateFree = []
      let ids = []

      nameParsedReports.filter((_report) => {
        if (!ids.includes(_report.id)) {
          duplicateFree.push(_report)
          ids.push(_report.id)
        }
      })

      return _.sortBy(duplicateFree, 'name').reverse()
    },
    getNextLevel () {
      return this.level + 1
    },
    getReport (report) {
      if (report.typeCode !== 'USR') {
        report.path = !report.path.includes('clients') ? report.path.replace('clients/', '') : report.path
      }

      if (!report.hasOwnProperty('carrierCode')) {
        this.$router.push({ name: 'ViewReport', params: { id: report.id, 'report': report } })
      } else if (report.hasOwnProperty('carrierCode')) {
        this.$router.push({ name: 'ViewDocumentScreen', params: { id: report.id, document: report } })
      } else {
        window.open(report.path)
      }
    },
    ellipsis: (event, mouseover) => {
      event.preventDefault()
      event.stopPropagation()
      const tooltip = event.currentTarget.querySelector('.tooltip-hover ')

      if (
        (event.currentTarget.classList.toString() === 'sidemenu-mask-dropdown' ||
        event.currentTarget.classList.toString() === 'sidemenu-mask-item')
      ) {
        if (event.currentTarget.children[0].scrollWidth > event.currentTarget.children[0].offsetWidth && mouseover) {
          tooltip._tooltip.show()
        } else {
          tooltip._tooltip.hide()
        }
      }
    },
    isGroup (menuItem) {
      if (
        menuItem.ItemType === parseInt(Constants.menu.type['group']) ||
        menuItem.ItemType === parseInt(Constants.menu.type['reports'])
      ) {
        return true
      }
      return false
    }
  }
}
</script>
